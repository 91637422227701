* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.App {
  width: 100vw;
  height: 100vh;
}

.Logo {
  width: 200px;
}

.tinyLogo {
  width: 60px;
}

.divisionLogo {
  width: 100px;
}

.Banner {
  width: 100%;
}

.Link {
  text-decoration: none;
  font-weight: 700;
  font-size: 1em;
  transition-duration: 100ms;
}

.Link:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.Link:visited {
  color: inherit;
}

.ATS {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 20% auto 20%;
  grid-template-rows: 50px auto;
  grid-template-areas:
    'topbar topbar topbar'
    'applicants display inspector';
}

.sidebar {
  overflow-y: scroll;
  height: 100%;
}

.ATSEntry {
  height: 80px;
  transition-duration: 200ms;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 8px;
}

.ATSEntry * {
  user-select: none;
}

.ATSEntry .info {
  margin-left: 16px;
}

.activeEntry {
  border: 2px solid rgba(0, 0, 0, 0.19);
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar h1 {
  font-size: 1em;
}

.viewer h1 {
  color: black;
}

.a-new {
  background-color: #4e8dc2;
}

.a-reviewed {
  background-color: #e9c46a;
}

.a-scheduled {
  background-color: #2a9d8f;
}

.a-rejected {
  background-color: rgb(210, 89, 89);
}

.pdf-viewer {
  border-radius: 5px;
  width: 420px;
}

.pdf-page {
  border-radius: 5px;
  border: 2px solid rgb(221, 221, 221);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete::placeholder {
  color: rgb(221, 221, 221);
}
